<template>
  <div class="banners-list d-flex flex-wrap">
    <template v-if="!isLoading">
      <div
        v-for="(banner, index) in banners"
        :key="banner.id"
        class="banners-list__item mb-4"
      >
        <BannerItem :banner="banner">
          <template #actions>
            <b-button
              variant="primary"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              class="p-1"
              title="Редактировать"
              @click="openEditBannerModal(banner)"
            >
              <b-icon icon="pencil-square" />
            </b-button>

            <b-button
              v-if="isDeleted"
              variant="success"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              class="ml-1 p-1"
              title="Восстановить"
              @click="openReopenAgreeModal(banner.id)"
            >
              <b-icon icon="arrow-counterclockwise" />
            </b-button>

            <b-button
              v-else
              variant="danger"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              class="ml-1 p-1"
              title="Удалить"
              @click="openDeleteAgreeModal(banner.id)"
            >
              <b-icon icon="trash" />
            </b-button>
          </template>
        </BannerItem>
        <div
          v-if="!banner.isDisabled"
          class="d-flex justify-content-between mt-1 bg-white border align-items-center font-weight-bold rounded p-1"
        >
          <b-button
            v-if="index"
            variant="primary"
            :type="$const.PRIMARY_BUTTON"
            size="sm"
            @click="$emit('replaceWithLeft', banner.id)"
          >
            <b-icon icon="arrow-left" />
          </b-button>
          <div v-else />

          Позиция - {{ banner.position }}

          <b-button
            v-if="index !== banners.length - 1"
            variant="primary"
            :type="$const.PRIMARY_BUTTON"
            size="sm"
            class="ml-1"
            @click="$emit('replaceWithRight', banner.id)"
          >
            <b-icon icon="arrow-right" />
          </b-button>
          <div v-else />
        </div>
      </div>
    </template>

    <template v-if="isLoading">
      <div
        v-for="item in 6"
        :key="item"
        class="banners-list__item mb-4"
      >
        <b-skeleton-img
          no-aspect
          width="350px"
          height="200px"
          class="rounded"
        />

        <b-skeleton-img
          no-aspect
          width="350px"
          height="25px"
          class="mt-1 rounded"
        />
      </div>
    </template>
  </div>
</template>

<script>
import BannerItem from '@/components/BannerManagement/BannerItem';

export default {
  name: 'BannersList',
  components: {
    BannerItem,
  },
  props: {
    banners: {
      type: Array,
      default: () => ([]),
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isDeleted: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    openEditBannerModal(propsData) {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'EditBannerModal',
        props: {
          bannerId: propsData.id,
          title: 'Редактирование банера',
        },
      });
    },
    async deleteBanner(id) {
      await this.$store.dispatch(this.$types.DELETE_BANNER, id);

      this.$store.commit(this.$types.TOGGLE_BANNERS_TRIGGER);
    },
    async reopenBanner(id) {
      await this.$store.dispatch(this.$types.REOPEN_BANNER, id);

      this.$store.commit(this.$types.TOGGLE_BANNERS_TRIGGER);
    },
    openDeleteAgreeModal(id) {
      this.$store.commit(
        this.$types.OPEN_MODAL,
        {
          name: 'AgreeModal',
          props: {
            cb: this.deleteBanner,
            cbArgs: [id],
            title: 'Удаление банера',
          },
        },
      );
    },
    openReopenAgreeModal(id) {
      this.$store.commit(
        this.$types.OPEN_MODAL,
        {
          name: 'AgreeModal',
          props: {
            cb: this.reopenBanner,
            cbArgs: [id],
            title: 'Восстановление банера',
          },
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.banners-list {
  margin-left: -20px;

  &__item {
    margin-left: 20px;
  }
}
</style>
