<template>
  <div class="banner-management w-100 pt-4">
    <div class="banner-management__content">
      <b-container fluid="xl">
        <div class="mb-4 d-flex justify-content-between align-items-end">
          <div class="h3 mb-0">
            Управление баннерами
          </div>

          <div class="d-flex">
            <b-button
              :variant="showDeleted ? 'primary' : 'outline-primary'"
              :disabled="isLoading"
              size="sm"
              class="border-0"
              :type="$const.PRIMARY_BUTTON"
              @click="toggleVisibility"
            >
              {{ showDeleted ? 'Показать не удаленные' : 'Показать удаленные' }}
            </b-button>

            <b-button
              variant="primary"
              :type="$const.PRIMARY_BUTTON"
              class="ml-2"
              @click="openCreatingBanner"
            >
              Новый баннер
            </b-button>
          </div>
        </div>
        <template v-if="!isLoading">
          <div
            v-for="category in bannerCategories"
            :key="category.categoryId"
            class="mb-3"
          >
            <div class="h4 mb-2">
              {{ category.category?.title }}
            </div>

            <BannersList
              v-if="category.category?.key !== 'MobileApp'"
              :is-loading="isLoading"
              :is-deleted="showDeleted"
              :banners="category.banners"
              @replaceWithLeft="replaceWithLeft($event, category)"
              @replaceWithRight="replaceWithRight($event, category)"
            />

            <template v-if="category.category?.key === 'MobileApp'">
              <div
                v-for="clientType in sortByClientType(category.banners)"
                :key="`clientType-${clientType.clientTypeId}`"
              >
                <div class="h5 mb-2">
                  {{ clientType.clientType?.title }}
                </div>

                <BannersList
                  :is-loading="isLoading"
                  :is-deleted="showDeleted"
                  :banners="clientType.banners"
                  @replaceWithLeft="replaceWithLeft($event, clientType)"
                  @replaceWithRight="replaceWithRight($event, clientType)"
                />
              </div>
            </template>
          </div>
        </template>

        <template v-if="isLoading">
          <div
            v-for="item in 2"
            :key="item.categoryId"
            class="mb-3"
          >
            <div class="mb-2">
              <b-skeleton
                type="input"
                size="sm"
                style="height: 20px;"
                class="w-25"
              />
            </div>

            <BannersList
              :is-loading="isLoading"
            />
          </div>
        </template>
      </b-container>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import * as types from '@/store/types';
import { BANNER_CATEGORY, BANNER_CLIENT_TYPE } from '@/helpers/consts';

import BannersList from '@/components/BannerManagement/BannersList';

export default {
  name: 'BannerManagement',
  components: {
    BannersList,
  },

  data: () => ({
    banners: [],
    isLoading: false,
    showDeleted: false,
    uniqueId: 991122, // при замене позиций используем несуществующее переходное знначение
  }),
  computed: {
    ...mapGetters({
      fetchBannersTrigger: types.BANNERS_TRIGGER,
    }),
    bannerCategories() {
      let categories = this.banners.reduce((acc, item) => {
        const category = acc.find((categoryItem) => categoryItem.categoryId === item.category);
        if (category) {
          category.banners.push(item);
        } else {
          acc.push({
            categoryId: item.category,
            category: BANNER_CATEGORY.find((categoryItem) => categoryItem.id === item.category),
            banners: [item],
          });
        }
        return acc;
      }, []);

      if (categories.length) {
        categories = categories.sort((a, b) => a.category?.order - b.category?.order);
      }
      categories.map((item) => ({ ...item, banners: item.banners.sort((a, b) => a.position - b.position) }));

      return categories;
    },
  },

  watch: {
    async fetchBannersTrigger() {
      await this.fetchBanners();
    },
  },

  async created() {
    await this.fetchBanners();
    this.uniqueId = 80000 + Math.floor(Math.random() * 2001);
  },

  methods: {
    async fetchBanners() {
      this.isLoading = true;
      try {
        const Categories = [2, 3, 4, 5, 6, 7];
        const params = new URLSearchParams();
        // if (query) params.append('text', query);
        if (Categories.length) Categories.forEach((item) => params.append('Categories', item));
        params.append('IsDisabled', this.showDeleted);
        this.banners = await this.$store.dispatch(this.$types.FETCH_BANNERS, params);
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    openCreatingBanner() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'EditBannerModal',
      });
    },
    async toggleVisibility() {
      this.showDeleted = !this.showDeleted;

      await this.fetchBanners();
    },
    async replacePosition(firstBannerId, secondBannerId) {
      const firstBanner = this.banners.find((item) => item.id === firstBannerId);
      const secondBanner = this.banners.find((item) => item.id === secondBannerId);
      await this.$store.dispatch(this.$types.UPDATE_BANNER, [{
        ...secondBanner,
        position: this.uniqueId,
      }]);
      await this.$store.dispatch(this.$types.UPDATE_BANNER, [{
        ...firstBanner,
        position: secondBanner.position,
      }]);
      this.$store.dispatch(this.$types.UPDATE_BANNER, [{
        ...secondBanner,
        position: firstBanner.position,
      }]);
      this.$store.commit(this.$types.TOGGLE_BANNERS_TRIGGER);
    },
    replaceAgree(firstId, secondId) {
      this.$store.commit(
        this.$types.OPEN_MODAL,
        {
          name: 'AgreeModal',
          props: {
            cb: this.replacePosition,
            cbArgs: [firstId, secondId],
            title: 'Вы уверены, что хотите поменять банеры местами ?',
          },
        },
      );
    },
    replaceWithLeft(id, category) {
      const bannerIndex = category.banners.findIndex((item) => item.id === id);
      const secondBanner = category.banners[bannerIndex - 1];
      this.replaceAgree(id, secondBanner.id);
    },
    replaceWithRight(id, category) {
      const bannerIndex = category.banners.findIndex((item) => item.id === id);
      const secondBanner = category.banners[bannerIndex + 1];
      this.replaceAgree(id, secondBanner.id);
    },
    sortByClientType(banners) {
      const clientTypes = banners.reduce((acc, item) => {
        const clientType = acc.find((clientTypeItem) => clientTypeItem.clientTypeId === item.clientType);
        if (clientType) {
          clientType.banners.push(item);
        } else {
          acc.push({
            clientTypeId: item.clientType,
            clientType: BANNER_CLIENT_TYPE.find((clientTypeItem) => clientTypeItem.id === item.clientType),
            banners: [item],
          });
        }
        return acc;
      }, []);

      return clientTypes;
    },
  },
};
</script>

<style lang="scss" scoped>
.banner-management {
  max-height: 100vh;

  &__content {
    height: 100%;
    overflow-y: auto;
  }
}

.new-banner {
  transition: .15s;
  cursor: pointer;
  height: 200px;

  &:hover {
    transform: translate(0, -4px);
  }
}
</style>
